import 'normalize.css';

import 'src/services/matomo.js';
import 'src/styles/main.scss';

import App from './App.svelte';

const app = new App({
    target: document.body,
});

function update() {
    document.documentElement.style.setProperty('--wh', `${window.innerHeight}px`);
}

update();

window.addEventListener('resize', update);

