<footer>
    <div class="links">
        <a href="/impressum">Impressum</a>
        <a href="/datenschutz">Datenschutz</a>
    </div>
    <span class="copyright">&copy; Synexion {new Date().getFullYear()}</span>
</footer>

<style lang="scss">footer {
  display: flex;
  padding: 2rem;
  background-color: #eae6fb;
  max-width: 80rem;
  margin-right: var(--deco-margin);
}
footer .links {
  display: flex;
}
footer .links a {
  color: #222222;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
footer .links a:not(:last-child) {
  margin-right: 2rem;
}
footer .links a:hover {
  border-bottom-color: #222222;
}
footer .copyright {
  margin-left: auto;
  align-self: flex-end;
  display: block;
  border-bottom: 1px solid transparent;
}
@media (max-width: 768px) {
  footer {
    padding: 3rem;
  }
}
@media (max-width: 640px) {
  footer {
    padding: 1rem;
  }
  footer .links {
    flex-direction: column;
    align-items: flex-start;
  }
  footer .links a:not(:last-child) {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}</style>
