<script>
    import { onMount } from 'svelte';

    import assetSynex from 'src/assets/images/synex.svg';
    import assetSynexion from 'src/assets/images/synexion.svg';
    import assetChevron from 'src/assets/images/chevron.svg';
    import assetServices from 'src/assets/images/services.webp';
    import assetConsulting from 'src/assets/images/consulting.webp';
    import assetWebsite from 'src/assets/images/website.webp';
    import assetApplications from 'src/assets/images/applications.webp';
    import assetSupport from 'src/assets/images/support.webp';
    import assetHosting from 'src/assets/images/hosting.webp';

    import Navigation from 'src/components/Navigation.svelte';
    import Menu from 'src/components/Menu.svelte';
    import Footer from 'src/components/Footer.svelte';

    let page;
    let pageScrollTop = 0;

    onMount(() => {
        page.addEventListener('scroll', ({ target }) => (pageScrollTop = target.scrollTop));
    });

    const links = {
        leistungen: {
            name: 'Leistungen',
            anchor: undefined, // will get populated by `bind`
        },
        consulting: {
            name: 'Consulting',
            anchor: undefined,
        },
        webseiten: {
            name: 'Webseiten',
            anchor: undefined,
        },
        software: {
            name: 'Software',
            anchor: undefined,
        },
        'it-support': {
            name: 'IT Support',
            anchor: undefined,
        },
        hosting: {
            name: 'Hosting',
            anchor: undefined,
        },
    };

    let selected = Object.keys(links)[0];

    // highlight nav entry based on scroll position
    $: for (const [href, { anchor }] of Object.entries(links).reverse()) {
        if (anchor && pageScrollTop > anchor.offsetTop - window.innerHeight / 3) {
            selected = href;
            break;
        }
    }
</script>

<div id="index" bind:this={page}>
    <section id="landing">
        <div class="appbar">
            <Menu primary />
        </div>
        <div class="pane-left" />
        <div class="pane-center">
            <div class="logos">
                <img id="synex" src={assetSynex} alt="Synex" />
                <img id="synexion" src={assetSynexion} alt="Synexion" />
                <div id="horizontal-line" />
                <div id="diagonal-line" />
            </div>
            <p id="slogan">Maßgeschneiderte IT-Lösungen für jeden Bedarf!</p>
            <img id="chevron" src={assetChevron} alt="downwards arrow" />
        </div>
    </section>
    <div class="appbar">
        <Menu />
    </div>
    <Navigation {selected} {links} />
    <main>
        <div class="anchor" id="leistungen" bind:this={links.leistungen.anchor} />
        <section>
            <h1 class="title">Leistungen</h1>
            <div class="content">
                Vom Design, über das Hosting, bis zur Wartung:<br />
                Wir betreuen Sie bei jeder Ihrer Fragen!<br />
                Überwinden Sie sich noch Heute Ihre Vorbehalte und lassen Sie Experten Ihre Infrastruktur entwerfen und warten.
                Dafür stehen wir Ihnen mit unseren Waffen gegen Technical Fearness zur Verfügung:<br />
                <ul>
                    <li>Consulting</li>
                    <li>Webseiten</li>
                    <li>Maßgeschneiderte Softwarelösungen</li>
                    <li>360°-Betreuung</li>
                    <li>Hosting für Ihre Anforderung</li>
                </ul>
            </div>
            <img class="preview" src={assetServices} alt="Services" />
        </section>
        <div class="blocker-md" />
        <div class="anchor" id="consulting" bind:this={links.consulting.anchor} />
        <section>
            <h1 class="title">Consulting</h1>
            <div class="content">
                Ihre Kompetenz ist Ihr Kapital, wir steigern die Effizienz Ihrer Tools!<br />
                Gemeinsam analysieren wir mit Ihnen Ihre aktuelle Unternehmensstruktur und unterstützen Sie in jedem Lösungsansatz.
                Dazu erarbeiten wir zu Ihren Wünschen technische Lösungen und Systemlandschaften nach dem neusten Stand der
                Technik!
            </div>
            <img class="preview" src={assetConsulting} alt="Consulting" />
        </section>
        <div class="blocker-md" />
        <div class="anchor" id="webseiten" bind:this={links.webseiten.anchor} />
        <section>
            <h1 class="title">Webseiten</h1>
            <div class="content">
                Vom Design, über die Entwicklung, bis zum Hosting:<br />
                Wir erstellen Ihnen einen umwerfenden Webauftritt!<br />
                Bearbeiten Sie anschließend Inhalte bequem über unser hauseigenes CMS
            </div>
            <img class="preview" src={assetWebsite} alt="Website" />
        </section>
        <div class="blocker-md" />
        <div class="anchor" id="software" bind:this={links.software.anchor} />
        <section>
            <h1 class="title">Maß&shy;geschneiderte Software</h1>
            <div class="content">
                Wenn der Durchschnitt nicht gut genug ist! Jedes Unternehmen hat individuelle Präferenzen und Abläufe.
                Sie sind mehr als ein Standard-Unternehmen! Warum nutzen Sie aber dennoch Standard-Software? Optimieren
                Sie Ihren Workflow mit Software die genau auf Ihr Unternehmen abgestimmt ist! <br />
                Plattformunabhängig und mit den neuesten Technologien!
            </div>
            <img class="preview" src={assetApplications} alt="Applications" />
        </section>
        <div class="blocker-md" />
        <div class="anchor" id="it-support" bind:this={links['it-support'].anchor} />
        <section>
            <h1 class="title">360° IT-Support</h1>
            <div class="content">
                Unser 360° IT-Support schützt Ihren Workflow vor unnötigen Belastungen. Mit unserem
                "Rundum-Sorglos-Service" werden Ihre IT-Dienste durch Experten verwaltet und regelmäßig gewartet.
                Verabschieden Sie sich von Ärgernissen mit Servern oder möglichen Sicherheitsupdates und legen Sie diese
                Aufgaben in die Hände von Experten. Und sollte doch mal ein Problem auftreten, stehen wir Ihnen
                unmittelbar zur Verfügung und nehmen Sie mit an die Hand!<br />
                Funktioniert nicht, gibts nicht!
            </div>
            <img class="preview" src={assetSupport} alt="Support" />
        </section>
        <div class="blocker-md" />
        <div class="anchor" id="hosting" bind:this={links.hosting.anchor} />
        <section>
            <h1 class="title">Hosting nach Ihren Anforderungen</h1>
            <div class="content">
                Web-Server hier, Mail-Server dort und die eigene Hardware im Hinterzimmer? Setzen sie diesem ein Ende
                und beziehen sie alles aus einer Hand! Vom Web-Server über VM´s bis zur eigenen Hardware im
                Rechenzentrum. Was immer sie benötigen, wir haben die passende Lösung parat! Oder doch gleich das
                gesamte Office in die private Cloud verlagern? - Bei uns kein Problem!
            </div>
            <img class="preview" src={assetHosting} alt="Hosting" />
        </section>
        <Footer />
    </main>
    <div class="pane-right" />
</div>

<style lang="scss">@font-face {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 300 700;
  src: url("src/assets/fonts/SpaceGrotesk-VariableFont_wght.woff2") format("woff2-variations");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 300;
  src: url("src/assets/fonts/Gilroy-Light.otf");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  src: url("src/assets/fonts/Gilroy-ExtraBold.otf");
}
#index,
#landing {
  display: grid;
  grid-template-columns: [start] minmax(16rem, 20vw) 1fr 20vw [end];
}
@media (max-width: 1024px) {
  #index,
#landing {
    grid-template-columns: [start] 3rem 1fr 3rem [end];
  }
}
@media (max-width: 768px) {
  #index,
#landing {
    display: block;
  }
}

#index {
  scroll-behavior: smooth;
  background-color: #ffffff;
  color: #222222;
  height: 100%;
  font: 500 1rem "Space Grotesk", sans-serif;
  line-height: 1.5;
  overflow-y: auto;
  grid-template-rows: min(75vw, calc(0.75 * var(--wh))) min-content;
}

#landing {
  background-color: #6320ee;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  color: white;
  grid-column: start/end;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
#landing .appbar {
  background-color: #6320ee;
  border-color: #6adad8;
}
@supports (backdrop-filter: blur(4px)) {
  #landing .appbar {
    backdrop-filter: blur(4px);
    background-color: transparent;
  }
}
#landing .pane-left {
  border-right: 1px solid #6adad8;
  grid-column: 1/2;
  grid-row: 1/2;
}
@media (max-width: 768px) {
  #landing .pane-left {
    display: none;
  }
}
#landing .pane-center {
  align-items: start;
  display: flex;
  flex-direction: column;
  grid-column: 2/3;
  grid-row: 1/2;
  justify-content: center;
  padding: 6rem 0;
}
@media (max-width: 1024px) {
  #landing .pane-center {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 4rem;
  }
}
@media (max-width: 640px) {
  #landing .pane-center {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#landing .pane-center .logos {
  width: 100%;
  max-width: 60rem;
}
#landing .pane-center .logos #synexion,
#landing .pane-center .logos #synex {
  position: relative;
  width: 100%;
  z-index: -1;
}
#landing .pane-center .logos #synex {
  margin-bottom: -10.4%;
}
#landing .pane-center .logos #synexion {
  margin-top: -4.9%;
  margin-bottom: -5.7%;
}
#landing .pane-center .logos #diagonal-line {
  position: relative;
  z-index: -2;
}
#landing .pane-center .logos #diagonal-line::before {
  content: "";
  position: absolute;
  right: 13.2%;
  width: calc(224vw + 27rem);
  height: 0;
  border-bottom: 1px solid #6adad8;
  transform: translate(50%) rotate(25deg);
}
#landing .pane-center .logos #horizontal-line {
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  border-bottom: 1px solid #6adad8;
  z-index: -2;
}
#landing .pane-center #slogan {
  color: #6adad8;
  font-size: 1.5rem;
  font-variation-settings: "wght" 500;
  margin: max(2rem, 4vw) min(8.07rem, 13.45%) max(2rem, 4vw) min(16.14rem, 26.9%);
}
@media (max-width: 640px) {
  #landing .pane-center #slogan {
    margin: 3rem 1rem;
  }
}
#landing .pane-center #chevron {
  height: 1.5rem;
  margin: 0 auto;
}

.appbar {
  align-items: center;
  display: none;
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 1;
}
@media (max-width: 1024px) {
  .appbar {
    width: 3rem;
    display: block;
  }
}
@media (max-width: 768px) {
  .appbar {
    width: auto;
    border-bottom: 1px solid black;
    right: 0;
    background-color: #ffffff;
  }
  @supports (backdrop-filter: blur(4px)) {
    .appbar {
      backdrop-filter: blur(4px);
      background-color: transparent;
    }
  }
}

main {
  grid-column: 2/4;
}
@media (max-width: 768px) {
  main {
    border-left: none;
  }
}
main .anchor {
  position: relative;
  display: block;
  top: -4rem;
}
main section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content 1fr;
  grid-column-gap: 4rem;
  grid-template-areas: "title preview" "content preview";
  position: relative;
  background-color: #ffffff;
  margin-top: var(--deco-margin);
  margin-right: var(--deco-margin);
  margin-bottom: calc(2 * var(--deco-margin));
  min-height: var(--deco-margin);
  max-width: 80rem;
  padding: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
main section:nth-of-type(even) {
  grid-template-areas: "preview title" "preview content";
}
main section::before {
  content: "";
  display: block;
  position: absolute;
  top: calc(-1 * var(--deco-margin));
  left: calc(-1 * var(--deco-margin));
  height: calc(2 * var(--deco-margin));
  width: calc(2 * var(--deco-margin));
  background-color: #eae6fb;
  z-index: -1;
}
@media (max-width: 1600px) {
  main section {
    margin-right: var(--deco-margin);
    grid-column-gap: 2rem;
    grid-template-areas: "title title" "content preview";
  }
  main section:nth-of-type(even) {
    grid-template-areas: "title title" "preview content";
  }
}
@media (max-width: 768px) {
  main section {
    display: block;
    padding: 3rem 3rem 3rem 3rem;
    border: none;
  }
  main section .content {
    margin-bottom: 1rem;
  }
}
@media (max-width: 640px) {
  main section {
    padding: 1rem 1rem 1rem 1rem;
  }
}
main section .title {
  grid-area: title;
}
main section .content {
  grid-area: content;
}
main section .preview {
  grid-area: preview;
  display: block;
  width: 100%;
  border-radius: 4px;
}
main .blocker-md {
  display: none;
}
@media (max-width: 768px) {
  main .blocker-md {
    display: block;
    width: 6rem;
    height: 3rem;
    background-color: #eae6fb;
  }
}
@media (max-width: 640px) {
  main .blocker-md {
    width: 100%;
  }
}</style>
