<script>
    import { drawer, toggleDrawer } from 'src/services/store';

    export let primary = false;
    let toggled;

    drawer.subscribe((value) => (toggled = value));
</script>

<button class="toggle-appbar" on:click={toggleDrawer} class:primary>
    {#if toggled}
        <!-- close icon -->
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="36px"
            height="36px"
            style="width: 36px; height: 36px"
        >
            <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
        </svg>
    {:else}
        <!-- menu icon -->
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="36px"
            height="36px"
            style="width: 36px; height: 36px"
        >
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
        </svg>
    {/if}
</button>

<style lang="scss">.toggle-appbar {
  display: none;
  border: none;
}
@media (max-width: 1024px) {
  .toggle-appbar {
    display: flex;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 3rem;
    height: 3rem;
  }
  .toggle-appbar:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .toggle-appbar.primary {
    border-color: #6adad8;
  }
  .toggle-appbar.primary svg {
    fill: #6adad8;
  }
  .toggle-appbar.primary:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
@media (max-width: 768px) {
  .toggle-appbar {
    border: none;
  }
}
.toggle-appbar svg {
  fill: black;
}</style>
